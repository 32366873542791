import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        carrito: {
            productos: [],
            total: 0,
        },
        usuario: null,
        descuento: 0,
    },

    getters: {
        productos: state => {
            return state.carrito.productos
        },
        usuario: state => {
            return state.usuario
        },
        total: state => {
            return state.carrito.total
        },
        descuento: state => {
            return state.descuento
        }
    },

    mutations: {
        addProduct(state, producto) {
            let old_product = false
            old_product = state.carrito.productos.find(e => (e.product_id == producto.product_id && e.level == producto.level))
            
            if(old_product){
                old_product.quantity++
            }else{
                state.carrito.productos.push(producto)
            }
        },

        setUsuario(state, usuario) {
            state.usuario = usuario
        },

        setProductos(state, productos) {
            state.carrito.productos = productos
        },

        setTotal(state, total) {
            state.carrito.total = total
        },

        setDescuento(state, descuento) {
            state.descuento = descuento
        },
        clearCarrito(state) {
            state.carrito = {
                productos: [],
                total: 0,
            }
        },
        clearStoreData(state, data) {
            state.usuario = data
            state.carrito = {
                productos: [],
                total: 0,
            }
            state.descuento = 0
        }
    }
})

export default store