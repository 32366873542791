import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Swal from "sweetalert2/dist/sweetalert2.js"
import store from './store/store'
import VueForm from "vue-form"

import "sweetalert2/dist/sweetalert2.css"
import VueCurrencyFilter from 'vue-currency-filter'
import mainMixins from './mainMixins.js'

import vSelect from 'vue-select'

Vue.config.productionTip = false

window.INSPIRO
window.moment = require('moment')

Vue.mixin(mainMixins)

Vue.component('v-select', vSelect)
window.router = router
// window.apiKS = "http://localhost/"
// window.apiKS = "http://store-api-etc.test/"
// window.apiKS = "https://adminetc.dconsultores.com/"
window.apiKS = "https://etc-store-api.dconsultores.com/"
// window.apiKS = "https://panel.etciberoamerica.com.gt/"
window.axios = axios
window.Swal = Swal
window.VueForm = VueForm
window.Fire = new Vue()
window.Vue = Vue
window.user = null
window.headers = null
window._ = require('lodash');
window.hostname = 'store.etciberoamerica.com'
// window.apiConekta = 'key_Kf5K7iPrxBh52WmbkbrmWsA' // desarrollo
//window.apiConekta = 'key_UAnsz7qgmkBmUSUQFE8CpdQ' // producción
// window.hostname = location.hostname
Vue.use(VueCurrencyFilter,
    {
      symbol : '',
      thousandsSeparator: ',',
      fractionCount: 2,
      fractionSeparator: '.',
      symbolPosition: 'front',
      symbolSpacing: true,
      avoidEmptyDecimals: undefined,
    })
    
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true
})

window.Toast = Toast

window.clientAPI = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMTRlZTFjMWM3YzQ1ZDc4OGI3MjliZmIxNThiYjZlYjc5ZTFiY2E3NjdiMmE3NmMxYTQwOThkYjc3MGZiZDY3NDZjYzA5ZTI4Y2JhMTAyNGEiLCJpYXQiOjE1OTY5OTcyMjgsIm5iZiI6MTU5Njk5NzIyOCwiZXhwIjoxNjI4NTMzMjI4LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.OHFhrJfV1Iz9-f955J5LmS9orPTrbnnwVigM3cTqKqseISwKC57yc71VbVhkmF6KSC93o_orrXQC-qOrPteiSpqRF9XiczacEluA4TDJ1nLl4g7YWCzrz1yMuFNJTBHmMxHjS2DlgoxCITEJWktM5x1L1NH7RDatWM8boY4ss86AD07vTSpISJ-O883UoFhDA5bZamiKynWgsmv4tib1mWGwjQAFU6NKPeJkpd6s15Cs40Wa7wdRJBvCV7hiHBo5mM5UACaZj7tzDGEpWDCk2lBjBWa-3Rieg4SVCvBev01t0DKVlHhjhqyvvQnqTP9l1nD078dNxvJPVDwWObS76grrm5S6KkbRAdfN7a1zlLxKb7ogZS4atQTmH5ZgOupcJpxsnQiOjtJIcQNV0g3WteijTg1F5TItKClDlXpHtHo-Z9h4iNG2_-G3lIC5oCtZtXFk-LFd4DBBqQQ4_HINHO5McMpE1C4iPqmkcR5Hh220_w9zvvF02VNrr2nYsCbe1CnoRUhZrukE-w06PpgoQNeb2HV3KY7uFiVrSQM_VseKdqFiTHm1cdgHBNaiRtiaECmmQG7ONw1YNzH_7k_4ZhfHuD8bXf5P8FQWZ9XjvTdFXXZAO3peU5CJht9hTk8BnIZ2soBis9V2XOyKVFiQqXRwadkwbg287v9H1vRGRik'
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
