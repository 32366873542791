import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Index.vue')
  },
  {
    path: '/privacy',
    name: 'Política de privacidad',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Privacy.vue')
  },
  {
    path: '/productos',
    name: 'Productos',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Productos.vue')
  },
  {
    path: '/detalle-producto/:id',
    name: 'DetalleProducto',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/DetalleProducto.vue')
  },
  {
    path: '/complete/:order',
    name: 'Complete',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Complete.vue')
  },
  {
    path: '/carrito',
    name: 'Carrito',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Carrito.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Login.vue')
  },
  {
    path: '/register',
    name: 'Registro',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Registro.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Checkout.vue')
  },
  {
    path: '/compras',
    name: 'Compras',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Compras.vue')
  },
  {
    path: '/detail/:id',
    name: 'Detalle Compras',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/DetalleCompras.vue')
  },
  {
    path: '/perfil',
    name: 'Actualización de datos',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Perfil.vue')
  },
  {
    path: '/password-recovery',
    name: 'Recuperación de password',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Recovery.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/store',
  routes,
})

export default router
