/* eslint-disable */
const mainMixins = {
    data() {
        return {

        }
    },
    methods: {
        async dropBenefitiary(user, index){
            axios.delete(this.apiKS + 'api/delete-benefitiary/'+user.id, { headers: headers }).then(response => {
                if(response.data.status){
                    this.model.user.splice(index, 1)
                }
            })
        },
        async getUserApi() {
            let status = false
            if(!user) {
                if(headers) {
                    if(localStorage.userETC) {
                        await axios.post(apiKS + 'api/userdecrypt', {usuario: localStorage.userETC}, { headers })
                        .then( response => {
                            if(response.data.status === false) {
                                Swal.fire({
                                    title: "Error",
                                    text: response.data.message,
                                    icon: "error",
                                    onClose: () => {
                                        Swal.close()
                                        router.replace('/login')
                                    }
                                })
                                localStorage.removeItem('userETC')
                                localStorage.removeItem('tokenETCSistema')
                                status = false
                                return
                            }
                            user = JSON.parse(response.data.user)
                            status = true
                        })
                        .catch( (error) => {
                            Swal.close()
                            this.catchError(error)
                            status = false
                        } )
                    }else{
                    }
                }else{
                    if(this.$route.path == '/checkout' || this.$route.path == '/compras'){
                        router.replace('/login')
                    }
                }
            }

            return status
        },
        async getCountries() {
            await axios.get(apiKS + 'api/countries', { headers: headers })
            .then( ({ data }) => (this.countries = data.data))
            .catch( error => {
                this.catchError(error)
            })
        },
        async getCountriesPublic() {
            await axios.get(apiKS + 'api/public/countries')
            .then( response => (this.countries = response.data))
            .catch( error => {
                this.catchError(error)
            })
        },
        async getLanguages() {
            await axios.get(apiKS + 'api/languages', { headers: headers })
            .then( ({ data }) => (this.languages = data.data))
            .catch( error => {
                this.catchError(error)
            })
        },
        async getSchools(value) {
            await axios.get(apiKS + 'api/public/schools')
            .then( ({ data }) => (this.schools = data))
            .catch( error => {
                this.catchError(error)
            })
        },
        async getStates(value) {
            await axios.get(apiKS + 'api/states?country_id=' + value, { headers: headers })
            .then( ({ data }) => (this.estados = data.data))
            .catch( error => {
                this.catchError(error)
            })
        },
        async getCities(value) {
            await axios.get(apiKS + 'api/cities?state_id=' + value, { headers: headers })
            .then( ({ data }) => (this.cities = data.data))
            .catch( error => {
                this.catchError(error)
            })
        },
        async getLevelSchool(value) {
            await axios.get(apiKS + 'api/public/school-levels/' + value)
                .then( ({ data }) => (this.levels = data))
                .catch( error => {
                    this.catchError(error)
                })
        },
        async getStatesPublic(value) {
            await axios.get(apiKS + 'api/public/states?country_id=' + value)
            .then( ({ data }) => (this.states = data))
            .catch( error => {
                this.catchError(error)
            })
        },
        async getCitiesPublic(value) {
            await axios.get(apiKS + 'api/public/cities?state_id=' + value)
            .then( ({ data }) => (this.cities = data))
            .catch( error => {
                this.catchError(error)
            })
        },

        async saveDataNoForm(url, model, urlReturn, emitEvent, closemodal, clearmodel, tipoMensaje, modelOriginal) {
            this.openLoading()
            await axios.post(apiKS + url, model, { headers })
                .then( response => {
                    Swal.close()
                    if(response.data.error){
                        Swal.fire({
                            icon: 'warning',
                            title: 'Oops...',
                            text: response.data.message,
                        })
                    }else{
                        if (response.data.status == true) {
                            if(tipoMensaje == 1 || tipoMensaje == undefined || tipoMensaje == null || tipoMensaje == 3) {
                                if(this.$route.path == '/checkout') {
                                    this.$store.commit('setProductos', [])
                                    this.$store.commit('setTotal', 0)
                                }
                                Swal.fire({
                                    title: "Exito",
                                    text: response.data.message,
                                    icon: "success",
                                    onClose: () => {
                                        if(urlReturn == '/complete'){
                                            console.log(response.data)
                                            router.push(urlReturn + '/' + response.data.order.key)
                                        }else{
                                            if(urlReturn != '' && tipoMensaje == 1)
                                                router.push(urlReturn)
                                            if(urlReturn != '' && tipoMensaje == 3)
                                                location.reload()
                                        }
                                    }
                                })
                            }else{
                                var $toast = toastr['success'](response.data.message, 'Exito')
                            }

                            if(emitEvent != '') {
                                Fire.$emit(emitEvent)
                            }
                            if(closemodal) {
                                this.closeModal(closemodal)
                            }
                            if(clearmodel == 1) {
                                this.clearModel()
                            }
                            if(clearmodel == 2) {
                                this.clearModelPartidas(modelOriginal)
                            }
                        }
                        if (response.data.status == false) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            })
                        }
                    }
                })
                .catch( error => {
                    this.catchError(error)
                })
        },

        async updateDataNoForm(url, model, urlReturn, emitEvent, closemodal, clearmodel, tipoMensaje, modelOriginal) {
            this.openLoading()
            await axios.put(apiKS + url, model, { headers })
                .then( response => {
                    Swal.close()
                    if (response.data.status == true) {
                        if(tipoMensaje == 1 || tipoMensaje == undefined || tipoMensaje == null) {
                            Swal.fire({
                                title: "Exito",
                                text: response.data.message,
                                type: "success",
                                onClose: () => {
                                    if(urlReturn != '')
                                        router.push(urlReturn)
                                }
                            })
                        }else{
                            var $toast = toastr['success'](response.data.message, 'Exito')
                        }

                        if(emitEvent != '') {
                            Fire.$emit(emitEvent)
                        }
                        if(closemodal) {
                            this.closeModal(closemodal)
                        }
                        if(clearmodel == 1) {
                            this.clearModel()
                        }
                        if(clearmodel == 2) {
                            this.clearModelPartidas(modelOriginal)
                        }
                    }
                    if (response.data.status == false) {
                        var $toast = toastr['error'](response.data.message, 'Error');
                    }
                })
                .catch( error => {
                    // this.catchError(error)
                })
        },

        async register(url, model, urlReturn, emitEvent, closemodal, clearmodel, tipoMensaje, modelOriginal) {
            this.openLoading()
            await axios.post(apiKS + url, model, { headers: headers })
            .then( response => {
                Swal.close()
                if (response.data.status == true) {
                    if(tipoMensaje == 1 || tipoMensaje == undefined || tipoMensaje == null || tipoMensaje == 3) {
                        Swal.fire({
                            title: "Exito",
                            text: response.data.message,
                            icon: "success",
                            onClose: () => {
                                if(urlReturn != '' && tipoMensaje == 1)
                                    router.push(urlReturn)
                                if(urlReturn != '' && tipoMensaje == 3)
                                    location.reload()
                            }
                        })
                    }else{
                        var $toast = toastr['success'](response.data.message, 'Exito')
                    }

                    if(emitEvent != '') {
                        Fire.$emit(emitEvent)
                    }
                    if(closemodal) {
                        this.closeModal(closemodal)
                    }
                    if(clearmodel == 1) {
                        this.clearModel()
                    }
                    if(clearmodel == 2) {
                        this.clearModelPartidas(modelOriginal)
                    }

                    if(this.$route.path.includes('/detalle-producto')) {
                        this.$store.commit("setDescuento", response.data.descuento)
                    }
                }
                if (response.data.status == false) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    })
                }
            })
            .catch( error => {
                this.catchError(error)
            })
        },
        async updateProfile(url, model, urlReturn, emitEvent, closemodal, clearmodel, tipoMensaje, modelOriginal) {
            this.openLoading()
            await axios.post(apiKS + url, model, { headers: headers })
                .then( response => {
                    Swal.close()
                    if (response.data.status == true) {
                        if(tipoMensaje == 1 || tipoMensaje == undefined || tipoMensaje == null || tipoMensaje == 3) {
                            axios.get(apiKS + 'api/user', { headers })
                                .then( response => {
                                    localStorage.userETC = response.data.userEncrypted
                                })
                            axios.post(apiKS + 'api/empty-cart', {}, { headers }).then(response => {
                                this.$store.state.carrito.productos = []
                            })
                            window.user = response.data.user
                            this.$store.commit('setUsuario', response.data.user)
                            Swal.fire({
                                title: "Exito",
                                text: response.data.message,
                                icon: "success",
                                onClose: () => {
                                    if(urlReturn != '' && tipoMensaje == 1)
                                        router.push(urlReturn)
                                    if(urlReturn != '' && tipoMensaje == 3)
                                        location.reload()
                                }
                            })
                        }else{
                            var $toast = toastr['success'](response.data.message, 'Exito')
                        }

                        if(emitEvent != '') {
                            Fire.$emit(emitEvent)
                        }
                        if(closemodal) {
                            this.closeModal(closemodal)
                        }
                        if(clearmodel == 1) {
                            this.clearModel()
                        }
                        if(clearmodel == 2) {
                            this.clearModelPartidas(modelOriginal)
                        }
                    }
                    if (response.data.status == false) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message,
                        })
                    }
                })
                .catch( error => {
                    this.catchError(error)
                })
        },

        async deleteDataNoForm(url, loadData, emitEvent) {
            
        },

        openLoading() {
            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
        },

        catchError(error) {
            Swal.close()
            if(error.response.status == 401 && window.location.pathname != '/store/register')
                router.replace('/login')
            if(error.response.status == 500 || error.response.status == 404 || error.response.status == 405){
                if(error.response.data.message == 'Undefined index: expirationYear'){
                    error.response.data.message = "Falta el dato Año de expiración o es incorrecto"
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.message,
                })
            }else{
                let localErrors = []
                if(typeof error.response?.data === 'object'){
                    localErrors = _.flatten(_.toArray(error.response.data.errors));
                }else{
                    localErrors = ['Algo salió mal :('];
                }

                for(let i = 0; i < localErrors.length; i++){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: localErrors[i],
                    })
                }
            }
        },

        async getProducts(url) {
            await axios.get(apiKS + url, { headers: headers })
                .then( ({ data }) => ( this.productosCatalogo = data))
                .catch( error => {
                    this.catchError(error)
                })
        },

        async getProductosUsuario(id_usuario) {
            await axios.get(apiKS + 'api/productosusuario?id_usuario=' + id_usuario, { headers: headers })
            .then( ({ data }) => (this.productosusuario = data))
            .catch( error => {
                this.catchError(error)
            })
        },

        async loadData(url) {
            this.openLoading()

            await axios.get(apiKS + url, { headers: headers })
            .then( response => {
                if(response.data.data) {
                    for(var prop in response.data.data) {
                        this.model[prop] = response.data.data[prop]
                    }

                } else {
                    for(var prop in response.data) {
                        this.model[prop] = response.data[prop]
                    }
                }
                /*if(response.data.id_pais != null && response.data.id_pais != '')
                    this.getEstados(response.data.id_pais, response.data.state_id)
                if(response.data.state_id != null && response.data.state_id != '')
                    this.getCities(response.data.state_id)
                if(response.data.id_cliente != null)
                    this.getCliente(response.data.id_cliente, 0)
                if(response.data.id_proveedor != null)
                    this.getProveedor(response.data.id_proveedor, 0)

                if(response.data.datos_mapa != "" && response.data.datos_mapa != null){
                    const coord = response.data.datos_mapa.split(',')
                    this.markers.push({
                        position: {
                            lat: parseFloat(coord[0]),
                            lng: parseFloat(coord[1])
                        }
                    })
                    navigator.geolocation.getCurrentPosition((position) => {
                        this.currentLocation = {
                            lat: parseFloat(coord[0]),
                            lng: parseFloat(coord[1])
                        }
                    })
                }*/
                Swal.close()
            })
            .catch( error => {
                this.catchError(error)
            })
        },

        async getProductosCarrito() {
            if(!this.$store.getters.productos.length && this.$store.getters.usuario) {
                await axios.get(apiKS + 'api/carrito', { headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.tokenETCSistema
                } })
                .then( (response) => {
                    this.$store.commit('setProductos', response.data.productos)
                    this.$store.commit('setTotal', response.data.total)
                })
                // .catch( error => {
                //     // this.catchError(error)
                // })
            }
        },

        async getCompras() {
            let url = ''
            if(this.$store.getters.usuario.distribuitor){
                url = `api/orders?user_id=${this.$store.getters.usuario.id}&distributor_id=${this.$store.getters.usuario.distribuitor.id}`
            }else{
                url = `api/orders?user_id=${this.$store.getters.usuario.id}`
            }

            await axios.get(apiKS + url, { headers: headers })
                .then( ({ data }) => (this.compras = data.data))
                .catch( error => {
                    this.catchError(error)
                })
        },

        async storeBenefits(cart_id, data) {
            await axios.post(apiKS + `api/benefitiaries/${cart_id}`,data, { headers: headers })
            .then( ({ data }) => (
                this.product.beneficiaries = data))
            .catch( error => {
                this.catchError(error)
            })
        },

        async downloadCodes(compra) {
            this.openLoading()
            await axios.post(apiKS + `api/download-codes`, compra, { headers: headers })
            .then(response => {
                Swal.close()
                let a = document.createElement('a')
                a.href = response.data.file
                a.download = response.data.fileName
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
            .catch( error => {
                this.catchError(error)
            })
        },
    }
}

export default mainMixins